import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { SaveTab } from './actions';


@State<any>({
  name: 'tabsetState'
})
@Injectable()
export class TabState {

  @Action(SaveTab)
  SaveTab({ patchState, getState, setState }: StateContext<any>, { id, value }: SaveTab) {
    if (Object.keys(getState()).length > 20) {
      setState({})
    }
    patchState({ [id]: value });
  }
}
